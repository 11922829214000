<template>
    <div class="border-bottom border-info">
        <div class="row">
            <div
                class="col-md-3 col-4 d-flex justify-content-center align-items-center py-3"
            >
                <button
                    class="w-24 h-24 p-0"
                    @click="goToMainPage(product.slug)"
                >
                    <img
                        class="w-100 h-100"
                        :src="product.references[0].images[0].url | image"
                        :alt="product.references[0].images[0].alt"
                        loading="lazy"
                    />
                </button>
            </div>
            <div
                class="col-md-9 col-8 d-flex flex-column justify-content-around py-3 px-1"
            >
                <div class="d-flex justify-content-between">
                    <router-link
                        :to="`/app/products/detail/${product.id}`"
                        class="btn-a p-0"
                    >
                        {{ product.name | cut(70) }}&nbsp;
                        {{ product.active ? "🟢" : "⚪" }}
                    </router-link>

                    <div class="d-flex">
                        <vs-tooltip
                            v-if="$ability.hasScope('sale:ListByProduct')"
                        >
                            <button
                                class="btn-a"
                                @click="
                                    goToMainPage(
                                        `/app/orders/product/${product.id}`
                                    )
                                "
                            >
                                📈
                            </button>
                            <template #tooltip>
                                <div class="content-tooltip">
                                    Ventas por producto
                                </div>
                            </template>
                        </vs-tooltip>
                        <vs-tooltip>
                            <button
                                class="btn-a"
                                @click="
                                    goToMainPage(
                                        `/app/products/clone/${product.id}`
                                    )
                                "
                            >
                                ©
                            </button>
                            <template #tooltip>
                                <div class="content-tooltip">
                                    Clonar producto
                                </div>
                            </template>
                        </vs-tooltip>
                    </div>
                </div>

                <small>{{ product.shortDescription }}</small>

                <div
                    class="d-flex flex-column flex-md-row justify-content-md-between"
                >
                    <div
                        class="d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">SKU </strong>
                        <div class="d-flex align-items-center">
                            <small>
                                {{ product.sku }}
                            </small>
                        </div>
                    </div>
                    <div
                        class="d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">Precio final </strong>
                        <small>
                            {{ product.priceOffer | money }}
                        </small>
                    </div>
                    <div
                        class="d-flex flex-md-column justify-content-between mr-1"
                    >
                        <strong class="text-success">
                            Precio sin descuento
                        </strong>
                        <small>
                            {{ product.price | money }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductTableItems",
    props: {
        product: {
            type: Object,
            required: true,
            default: () => ({
                id: 1,
                brandSlug: "",
                sku: "sku",
                name: "Cargando",
                slug: "cargando",
                shortDescription: "Cargando...",
                starts: "5.00",
                priceOffer: "150.000",
                price: "150.000",
                references: []
            })
        }
    },
    emits: ["changeQuantity"],
    methods: {
        goToMainPage(slug) {
            window.open(`${slug}`, "_blank").focus();
        }
    }
};
</script>
