<template>
    <main class="d-flex flex-column mb-4">
        <h1>Búsqueda de productos en el {{ $siteType }}</h1>
        <div class="d-flex justify-content-center align-items-center">
            <vs-input
                id="search-items"
                v-model="keySearch"
                autocomplete="off"
                name="product-key"
                border
                type="text"
                placeholder="Buscar productos por nombre O SKU .."
                class="my-4 w-100"
                @input="onInput"
                @click-icon="advanceSearch"
                @keyup.enter="advanceSearch"
            >
                <template #icon> 🔎 </template>
            </vs-input>
            <barcode-scanner-button @change="searchByBarcode" />
        </div>
        <div>
            <product-table-items
                v-for="product in items"
                :key="product.id"
                class="col-md-11 col-12 mx-auto"
                :product="product"
            />
        </div>
        <div v-if="isLoading" class="d-flex">
            <h1>Cargando....</h1>
        </div>
    </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ProductTableItems from "@/components/products/ProductTableItems.vue";
import BarcodeScannerButton from "@/components/utils/BarcodeScannerButton.vue";

export default {
    name: "ProductsIndex",
    components: {
        ProductTableItems,
        BarcodeScannerButton
    },
    layout: "app",
    meta: {
        roles: ["administrator", "support"]
    },
    data: () => ({
        keySearch: "",
        isLoading: false,
        items: []
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("product:Search", "/app");
    },
    methods: {
        ...mapActions("products", ["listAllProducts", "getProductByBarcode"]),
        async advanceSearch(required = true) {
            if (!this.keySearch) return;
            this.isLoading = true;
            try {
                this.items = await this.listAllProducts({
                    keyword: this.keySearch,
                    required
                });
            } catch (error) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async onInput() {
            this.debounce(this.advanceSearch, 500, this.keySearch, false);
        },
        debounce(func, time, ...params) {
            if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                func(...params);
            }, time);
        },
        async searchByBarcode(barcode) {
            this.isLoading = true;
            this.keySearch = "";
            try {
                const productsAux = await this.getProductByBarcode({ barcode });
                this.items = [productsAux];
            } catch (error) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
