<template>
    <div>
        <vs-tooltip border>
            <vs-button border icon @click="openScanner">📡</vs-button>

            <template #tooltip> Buscar con Scanner </template>
        </vs-tooltip>

        <vs-dialog
            v-model="openModalScanner"
            overflow-hidden
            auto-width
            @close="stopListener"
        >
            <div class="con-content">
                <div
                    class="d-flex flex-column justify-content-center align-items-center"
                >
                    <h3 class="my-2 mx-3">
                        {{
                            $isPhone
                                ? "Estas usando un dispositivo movil, el scanner no puede operar."
                                : "Por favor, conecta el scanner"
                        }}
                    </h3>

                    <div>
                        <div class="d-flex mt-4">
                            <vs-input
                                id="barcode"
                                v-model="barcode"
                                type="text"
                                label="Ingreso Manual"
                                name="barcode"
                                class="input-theme w-100 mr-2 border-custom"
                                aria-label="Barcode"
                                icon-after
                                @focus="stopListener"
                                @blur="startListener"
                                @keyup.enter="onClickSearch"
                            >
                            </vs-input>
                            <vs-button circle icon @click="onClickSearch">
                                🔎
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
export default {
    name: "BarcodeScannerButton",
    emits: ["change"],
    data: () => ({
        openModalScanner: false,
        timer: null,
        barcode: ""
    }),
    mounted() {
        this.startListener();
    },
    beforeDestroy() {
        this.stopListener();
    },
    methods: {
        handleListener(event) {
            if (!this.openModalScanner) return;
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.barcode += event.key;
            this.timer = setTimeout(() => {
                this.$emit("change", this.barcode);
                this.stopListener();
                this.barcode = "";
                this.openModalScanner = false;
            }, 20);
        },
        openScanner() {
            this.openModalScanner = true;
            this.startListener();
        },
        startListener() {
            if (!this.$isPhone) {
                document.addEventListener("keydown", this.handleListener);
            }
        },
        stopListener() {
            if (!this.$isPhone) {
                document.removeEventListener("keydown", this.handleListener);
            }
        },
        onClickSearch() {
            this.$emit("change", this.barcode);
            this.barcode = "";
            this.openModalScanner = false;
        }
    }
};
</script>
